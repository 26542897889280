import React, { useState } from 'react'
import App from './App'

const ButtonPrompt = () => {
    const [accepted, setaccepted] = useState(false)
    if(accepted){
        return <App />
    }
    return (
        <button onClick={()=>{setaccepted(true)}}>start</button>
    )
}

export default ButtonPrompt