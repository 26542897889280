import { useEffect, useRef, useState } from 'react'
import { Howl } from 'howler';
import styles from './App.module.css';

import music from './media/music/Rock That Beat.mp3'

import intro from './media/images/intro.jpg'
import grundschritt from './media/images/grundschritt.jpg'
import crisscross from './media/images/crisscross.jpg'
import bell from './media/images/bell.jpg'
import kreuzundquer from './media/images/kreuzundquer.jpg'
import tiptop from './media/images/tiptop.jpg'
import drehung from './media/images/drehung.jpg'

const BPM = 140
const interval = 60000 / BPM

const constantdances = {
  0: {
    name: "Intro",
    image: intro,
    moves: [
      {
        x: 0,
        y: "12%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "28%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "46%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "63%",
        width: "100%",
        height: "20%"
      }
      ,
      {
        x: 0,
        y: "80%",
        width: "100%",
        height: "20%"
      }
    ]
  },
  1: {
    name: "Grundschritt",
    image: grundschritt,
    moves: [
      {
        x: "4%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "21%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "38%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "55%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "72%",
        y: "25%",
        width: "20%",
        height: "70%"
      }
    ]
  },
  2: {
    name: "Crisscross",
    image: crisscross,
    moves: [
      {
        x: 0,
        y: "12%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "28%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "46%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "63%",
        width: "100%",
        height: "20%"
      }
      ,
      {
        x: 0,
        y: "80%",
        width: "100%",
        height: "20%"
      }
    ]
  },
  3: {
    name: "bell",
    image: bell,
    moves: [
      {
        x: 0,
        y: "12%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "28%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "46%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "63%",
        width: "100%",
        height: "20%"
      }
      ,
      {
        x: 0,
        y: "80%",
        width: "100%",
        height: "20%"
      }
    ]
  },
  4: {
    name: "kreuzundquer",
    image: kreuzundquer,
    moves: [
      {
        x: 0,
        y: "12%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "28%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "46%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "63%",
        width: "100%",
        height: "20%"
      }
      ,
      {
        x: 0,
        y: "80%",
        width: "100%",
        height: "20%"
      }
    ]
  },
  5: {
    name: "tiptop",
    image: tiptop,
    moves: [
      {
        x: 0,
        y: "12%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "28%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "46%",
        width: "100%",
        height: "20%"
      },
      {
        x: 0,
        y: "63%",
        width: "100%",
        height: "20%"
      }
      ,
      {
        x: 0,
        y: "80%",
        width: "100%",
        height: "20%"
      }
    ]
  },
  6: {
    name: "drehung",
    image: drehung,
    moves: [
      {
        x: "4%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "21%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "38%",
        y: "25%",
        width: "20%",
        height: "70%"
      },
      {
        x: "72%",
        y: "25%",
        width: "20%",
        height: "70%"
      }
    ]
  },
}

const dancestructure = [
  0, 1, 0, 2, 0, 3, 0, 4, 0, 5, 0, 6, 6, 0, 1, 0, 2, 0, 3, 0, 4, 0, 5, 0, 6, 6, 0
]

function App() {
  //const [dances, setdances] = useState()
  const image = useRef()
  const infodiv = useRef()
  const inited = useRef(false)
  const [next, setnext] = useState("")

  //let currentMoveObject = dances ? dances[dancestructure[currentdance]].moves[currentMove] : null

  async function fetchDances() {
    let resultobject = {}
    for (let i = 0; i < Object.keys(constantdances).length; i++) {
      const key = Object.keys(constantdances)[i]
      const value = constantdances[key]
      const resp = await fetch(value.image)
      const newurl = URL.createObjectURL(await resp.blob())
      resultobject[key] = {
        ...value,
        image: newurl
      }
    }
    return resultobject
  }

  useEffect(() => {
    if (inited.current) {
      return
    }
    inited.current = true
    fetchDances().then((dances) => {
      var sound = new Howl({
        src: [music],
        volume: 0.02,
        onplay: () => {
          setTimeout(() => {
            var currentdance = 0;
            var currentmove = 0;
            image.current.src = dances[dancestructure[currentdance]].image
            let obj = dances[dancestructure[currentdance]].moves[currentmove]
            infodiv.current.style.left = obj.x
            infodiv.current.style.top = obj.y
            infodiv.current.style.width = obj.width
            infodiv.current.style.height = obj.height
            setInterval(() => {
              if (dances[dancestructure[currentdance]].moves[currentmove + 1]) {
                console.log("adding")
                //setcurrentMove(currentMove + 1)
                currentmove++;
                let obj = dances[dancestructure[currentdance]].moves[currentmove]
                infodiv.current.style.left = obj.x
                infodiv.current.style.top = obj.y
                infodiv.current.style.width = obj.width
                infodiv.current.style.height = obj.height
              } else if (dances[dancestructure[currentdance + 1]] != undefined) {
                //setcurrentMove(0)
                //setcurrentdance(currentdance + 1)
                currentdance++
                currentmove = 0
                let obj = dances[dancestructure[currentdance]].moves[currentmove]
                setnext(dances[dancestructure[currentdance+1]].name)
                console.log(dances[dancestructure[currentdance]].name)
                infodiv.current.style.left = obj.x
                infodiv.current.style.top = obj.y
                infodiv.current.style.width = obj.width
                infodiv.current.style.height = obj.height
                image.current.src = dances[dancestructure[currentdance]].image

              }

            }, interval);
          }, interval * 5 + 1800 + interval);
        }
      });
      console.log("PLAY")
      sound.seek((interval * (4 * 14)) / 1000)
      sound.play()
    })
  }, [next])

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <>
          <h3>Next: {next}</h3>
          <div className={styles.infodiv} ref={infodiv} />
          <img className={styles.img} ref={image} />
        </>
      </div>
    </div>
  );
}

export default App;
